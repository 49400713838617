.password-type-button-child {
  position: absolute;
  top: 0;
  left: calc(50% - 167px);
  border-radius: 10px;
  width: 334px;
  height: 61px;
}
.password {
  position: absolute;
  top: 0;
  left: calc(50% - 151px);
  display: flex;
  align-items: center;
  width: 307px;
  height: 61px;
}
.email-type-button-in,
.password-type-button-in {
  left: calc(50% - 167.5px);
  width: 334px;
  text-align: left;
  background-color: #ccc; /* Greyed-out background color */
  border: none; /* Remove the border */

}
.password-type-button-in {
  position: absolute;
  top: 311px;
  height: 61px;
}
.email-type-button-in {
  top: 234px;
}
.email-type-button-in,
.sign-in-in,
.sign-in-button-in {
  position: absolute;
  height: 61px;
}
.sign-in-in {
  top: 0;
  left: calc(50% - 151px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 307px;
}
.sign-in-button-in {
  top: 392px;
  left: calc(50% - 167.5px);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 334px;
  cursor: pointer;
  font-size: 30px;
  color: #fff;
  font-family: "Archivo Black";
}
.google-button-icon {
  position: absolute;
  top: 544px;
  left: calc(50% - 175.5px);
  width: 342px;
  height: 95px;
}
.forgot-your-password,
.sign-in-header {
  position: absolute;
  top: 453px;
  left: calc(50% - 157.5px);
  display: flex;
  color: #b3d5e3;
  align-items: center;
  justify-content: center;
  width: 307px;
  height: 61px;
}
.sign-in-header {
  top: 167px;
  left: calc(50% - 151.5px);
  font-size: 40px;
  color: #a6d4c1;
}
.back-button-icon {
  position: absolute;
  top: 43px;
  left: 16px;
  width: 46px;
  height: 46px;
  overflow: hidden;
  cursor: pointer;
}
.asthetic-lines-icon {
  position: absolute;
  top: 28px;
  left: 0;
  width: 334px;
  height: 3px;
}
.google-divider,
.or {
  position: absolute;
  height: 61px;
}
.or {
  top: 0;
  left: calc(50% - 153px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 307px;
}
.google-divider {
  top: 490px;
  left: 29px;
  width: 334px;
}
.password-transparency-button {
  position: absolute;
  top: 330px;
  left: 323px;
  width: 23px;
  height: 23px;
  overflow: hidden;
}
.sign-in-screen {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 852px;
  overflow: hidden;
  text-align: center;
  font-size: 20px;
  color: #a6a4a4;
  font-family: Archivo;
}
